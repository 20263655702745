import React, { useRef, useState, useCallback, useEffect } from "react"
import Img from "gatsby-image"
import debounce from "lodash.debounce"

import ChevronLeftIcon from "../images/icons/chevron-left.inline.svg"
import ChevronRightIcon from "../images/icons/chevron-right.inline.svg"

export const CompanySlider = ({ children }) => {
  const scrollRef = useRef(null)

  const [imageWidth, setImageWidth] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [maxScrollLeft, setMaxScrollLeft] = useState(0)

  // update scrollLeft on scroll
  useEffect(() => {
    if (!scrollRef.current) return

    function setScrollLeftOnScroll() {
      setScrollLeft(scrollRef.current.scrollLeft)
    }

    const debouncedSetScrollLeftOnScroll = debounce(setScrollLeftOnScroll, 50, {
      trailing: true,
    })

    scrollRef.current.addEventListener(
      "scroll",
      debouncedSetScrollLeftOnScroll,
      false
    )

    return () =>
      scrollRef.current.removeEventListener(
        "scroll",
        debouncedSetScrollLeftOnScroll,
        false
      )
  }, [scrollRef.current])

  // set maxScrollLeft
  useEffect(() => {
    if (!scrollRef.current) return

    const imageCount = scrollRef.current.children.length
    const imageWidth =
      scrollRef.current.firstChild.getBoundingClientRect().width + 48

    setImageWidth(imageWidth)

    setMaxScrollLeft(
      imageCount * imageWidth - 48 - scrollRef.current.clientWidth
    )
  }, [scrollRef])

  // scroll on scrollLeft change
  useEffect(() => {
    if (!scrollRef.current) return

    scrollRef.current.scrollLeft = scrollLeft
  }, [scrollLeft])

  // scroll to next image or to maxScrollLeft
  function scrollNext() {
    if (scrollLeft < maxScrollLeft) {
      const newScroll = scrollLeft + imageWidth

      if (newScroll > maxScrollLeft) {
        setScrollLeft(maxScrollLeft)
      } else {
        setScrollLeft(newScroll)
      }
    }
  }

  // scroll to prev image or to 0
  function scrollPrev() {
    if (scrollLeft >= 0) {
      const newScroll = scrollLeft - imageWidth

      if (newScroll >= 0) {
        setScrollLeft(newScroll)
      } else {
        setScrollLeft(0)
      }
    }
  }

  return (
    <div className="slider__viewport">
      <button
        className={`button slider__button slider__button--prev ${
          scrollLeft > 0 ? "" : "slider__button--hidden"
        }`}
        onClick={scrollPrev}
      >
        <ChevronLeftIcon />
      </button>

      <div className="slider__carousel" ref={scrollRef}>
        {children}
      </div>

      <button
        className={`button slider__button slider__button--next ${
          scrollLeft < maxScrollLeft ? "" : "slider__button--hidden"
        }`}
        onClick={scrollNext}
        style={{ opacity: scrollLeft < maxScrollLeft ? "1" : "0" }}
      >
        <ChevronRightIcon />
      </button>
    </div>
  )
}

export const CompanySliderImage = ({ imgFluid }) => (
  <Img fluid={imgFluid} alt="slider" className="slider__slide img-shadow" />
)
