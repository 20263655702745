import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import markdownit from "markdown-it"
import React from "react"
import { CompanySlider, CompanySliderImage } from "../components/CompanySlider"
import Layout from "../components/layout"
import SEO from "../components/seo"
import UserIcon from "../images/icons/user.inline.svg"

const md = markdownit({ html: true })

const TeamMember = ({ img, name, role, descr }) => {
  return (
    <li className="company__team-member">
      <Img fluid={img} alt={name} className="company__team-member-img" />
      <h4 className="company__team-member-name">{name}</h4>
      <div className="company__team-member-role">{role}</div>
      <span
        className="company__team-member-descr"
        dangerouslySetInnerHTML={{ __html: descr }}
      />
    </li>
  )
}

const Company = ({ data }) => {
  console.log(data)
  const { allPagesYaml } = data
  return (
    <Layout>
      <SEO title="Company" />

      <div className="container company">
        <div className="company__hero grid">
          <div className="company__title">
            <h1>{allPagesYaml.edges[0].node.title}</h1>

            <Link to="/contact" className="button">
              Talk to us
            </Link>
          </div>

          <CompanySlider>
            <CompanySliderImage imgFluid={data.slide1.childImageSharp.fluid} />
            <CompanySliderImage imgFluid={data.slide2.childImageSharp.fluid} />
            <CompanySliderImage imgFluid={data.slide3.childImageSharp.fluid} />
            <CompanySliderImage imgFluid={data.slide1.childImageSharp.fluid} />
            <CompanySliderImage imgFluid={data.slide2.childImageSharp.fluid} />
            <CompanySliderImage imgFluid={data.slide3.childImageSharp.fluid} />
          </CompanySlider>
        </div>

        <div className="company__intro grid">
          <div
            className="company__intro-text"
            dangerouslySetInnerHTML={{
              __html: md.render(allPagesYaml.edges[0].node.description),
            }}
          ></div>

          <div className="company__intro-careers">
            <UserIcon />

            <h4>
              Sound interesting?
              <br />
              <br />
              We’re growing!
            </h4>
            <a
              href="https://weworkremotely.com/company/upright-labs"
              className="button button--blue-light"
              target="_blank"
              rel="noreferrer"
            >
              Explore careers
            </a>
          </div>
        </div>

        <ul className="company__team">
          {allPagesYaml.edges[0].node.team.map((member, i) => {
            return (
              <TeamMember
                key={i}
                img={member.img.childImageSharp.fluid}
                name={member.name}
                role={member.role}
                descr={member.description}
              />
            )
          })}
        </ul>
      </div>

      <article className="company__story">
        <div className="container grid">
          <div className="company__story-holder">
            <h2 className="h2--bold">Our story</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: md.render(allPagesYaml.edges[0].node.story),
              }}
            ></div>
          </div>

          <div className="company__whatwedo">
            <h4 className="h4--bold">Who we are</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: md.render(allPagesYaml.edges[0].node.what_we_do),
              }}
            ></div>

            <hr />

            <h4 className="h4--bold">Who we aren't</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: md.render(allPagesYaml.edges[0].node.what_we_dont_do),
              }}
            ></div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query {
    allPagesYaml(filter: { page_key: { eq: "company" } }) {
      edges {
        node {
          title
          description
          story
          what_we_do
          what_we_dont_do
          team {
            description
            name
            role
            img {
              childImageSharp {
                fluid(maxHeight: 288) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    slide1: file(relativePath: { eq: "slides/1.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 288) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide2: file(relativePath: { eq: "slides/2.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 288) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide3: file(relativePath: { eq: "slides/3.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 288) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide1: file(relativePath: { eq: "slides/1.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 288) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide2: file(relativePath: { eq: "slides/2.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 288) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide3: file(relativePath: { eq: "slides/3.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 288) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    OfficeImg: file(relativePath: { eq: "office.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1104) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Company
